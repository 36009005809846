import axios from "axios";
import authHeader from "./auth-header";

const EMPRUNTS_URL = `${process.env.REACT_APP_URI}`;
const EMPRUNT_URL = `${process.env.REACT_APP_URI}`;

const getEmprunt = (id) => {
  return axios.get(EMPRUNTS_URL + `emprunt/${id}/`, {
    headers: authHeader(),
  });
};

const getEmpruntFiltered = (props) => {
  return axios.get(
    EMPRUNTS_URL +
      `emprunt/filter/?nom_emprunteur=${props.nom_emprunteur}&prenom_emprunteur=${props.prenom_emprunteur}&societe_emprunteur=${props.societe_emprunteur}&telephone_emprunteur=${props.telephone_emprunteur}`,
    {
      headers: authHeader(),
    }
  );
};

const getAllEmprunt = () => {
  return axios.get(EMPRUNTS_URL + `emprunt/list/`, {
    headers: authHeader(),
  });
};

const addEmprunt = (props) => {
  return axios.post(EMPRUNT_URL + `emprunt/`, props, {
    headers: authHeader(),
  });
};

const editEmprunt = (props) => {
  return axios.put(
    EMPRUNT_URL + `emprunt/${props.emprunt.id}/`,
    props.emprunt,
    {
      headers: authHeader(),
    }
  );
};

const getAllMateriel = () => {
  return axios.get(EMPRUNTS_URL + `materiel/list/`, {
    headers: authHeader(),
  });
};

const empruntService = {
  getEmprunt,
  addEmprunt,
  editEmprunt,
  getAllEmprunt,
  getAllMateriel,
  getEmpruntFiltered
};

export default empruntService;
