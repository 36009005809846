import React from "react";
import TextFieldCustom from "./fields/TextFieldCustom";
import SelectCustom from "./fields/SelectCustom";
import SelectButtonCustom from "./fields/SelectButtonCustom";
import TextFieldEval from "./fields/TextFieldEval";

const Components = {
  textfield: TextFieldCustom,
  select: SelectCustom,
  selectbutton: SelectButtonCustom,
  textfieldeval : TextFieldEval,
};

export default (block,handleChange,state) => {
  if (typeof Components[block.component] !== "undefined") {
    return React.createElement(Components[block.component], {
      block: block,
      handleChange:handleChange,
      state:state,
      key: block.id,
    });
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block.id }
  );
};
