import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../redux/slices/auth";

export default function HeaderBar() {
  let history = useHistory();

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  function clickHome() {
    history.push("/home");
  }

  function clickRecherche() {
    history.push("/recherche-emprunt");
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{ background: "purple" }} position="static">
        <Toolbar>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Button onClick={clickHome} variant="outlined" color="inherit">
            Page accueil
          </Button>
          </Typography>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Button onClick={clickRecherche} variant="outlined" color="inherit">
            Recherche
          </Button>
          </Typography>
          <Button onClick={logOut} variant="outlined" color="inherit">
            Deconnexion
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
