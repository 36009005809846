import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import EmpruntService from "../../services/emprunt.services";


export const getempruntfiltered = createAsyncThunk(
  "emprunt/list/",
  async (props, thunkAPI) => {
    try {
      const response = await EmpruntService.getEmpruntFiltered(props);
      return { data: response.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(
        setMessage({
          text: message + "  " + JSON.stringify(error.response.data),
          display: true,
          color: "error",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getemprunt = createAsyncThunk(
  "emprunt/:id",
  async (props, thunkAPI) => {
    try {
      const response = await EmpruntService.getEmprunt(props);
      return { data: response.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(
        setMessage({
          text: message + "  " + JSON.stringify(error.response.data),
          display: true,
          color: "error",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getallemprunt = createAsyncThunk(
  "emprunt/list",
  async (props, thunkAPI) => {
    try {
      const response = await EmpruntService.getAllEmprunt();
      return { data: response.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(
        setMessage({
          text: message + "  " + JSON.stringify(error.response.data),
          display: true,
          color: "error",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getallmateriel = createAsyncThunk(
  "emprunt/list",
  async (props, thunkAPI) => {
    try {
      const response = await EmpruntService.getAllMateriel();
      return { data: response.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(
        setMessage({
          text: message + "  " + JSON.stringify(error.response.data),
          display: true,
          color: "error",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const addemprunt = createAsyncThunk(
  "emprunt/add",
  async (props, thunkAPI) => {
    try {
      await EmpruntService.addEmprunt(props);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(
        setMessage({
          text: message + "  " + JSON.stringify(error.response.data),
          display: true,
          color: "error",
        })
      );

      return thunkAPI.rejectWithValue();
    }
  }
);

export const editemprunt = createAsyncThunk(
  "emprunt/:id",
  async (props, thunkAPI) => {
    try {
      await EmpruntService.editEmprunt(props);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      thunkAPI.dispatch(
        setMessage({
          text: message + "  " + JSON.stringify(error.response.data),
          display: true,
          color: "error",
        })
      );

      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  selectedEmprunt: {},
  listmateriel: [],
  
};

const empruntSlice = createSlice({
  name: "emprunt",
  initialState,
  reducers: {
    setEmprunt: (state, data) => {
      console.log("data",data)
      return {
        ...state,
        selectedEmprunt: data.payload.data,
      };
    },
    resetEmprunt: (state) => {
      return {
        ...state,
        selectedEmprunt: {},
      };
    },

    resetListEmprunt: (state) => {
      return {
        ...state,
        list: {},
      };
    },
  },

  extraReducers: {
    [getemprunt.fulfilled]: (state, action) => {
      state.selectedEmprunt = action.payload.data;
    },
    [getemprunt.rejected]: (state, action) => {
      state.selectedEmprunt = {};
    },

    [addemprunt.fulfilled]: (state, action) => {
      state.selectedEmprunt = {};
    },
    [addemprunt.rejected]: (state, action) => {
      state.selectedEmprunt = action.payload.data;
    },

    [editemprunt.fulfilled]: (state, action) => {
      state.selectedEmprunt = {};
    },
    [editemprunt.rejected]: (state, action) => {
      state.selectedEmprunt = {};
    },

    [getallmateriel.fulfilled]: (state, action) => {
      state.listmateriel = action.payload.data;
    },
  },
});

export const { setEmprunt, handleChangeEval, resetEmprunt, resetListEmprunt } =
  empruntSlice.actions;
export default empruntSlice.reducer;

// const { reducer } = empruntSlice;

// export default reducer;
