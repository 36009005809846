import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";

export default function Home() {
    let history = useHistory();
    function clickEmprunt() {
        history.push("/emprunt");
      }
    
      function clickRetour() {
        history.push("/emprunt-filter");
      }
  return (
    <div>
      <Box pt={5}></Box>

      <h1 align="center">MATERIELS - CEFREP</h1>
      <Box pt={30}></Box>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <Button
            style={{ width: "100%", height: "300px" }}
            variant="contained"
            size="large"
            onClick={clickEmprunt}
          >
            EMPRUNTS OU ESSAIS
          </Button>
        </Grid>

        <Grid item>
          <Button
            style={{ width: "100%", height: "300px" }}
            variant="contained"
            size="large"
            onClick={clickRetour}
          >
            RETOUR MATERIEL
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
