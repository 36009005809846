import { createSlice } from "@reduxjs/toolkit";

const initialState = {text:"",display:false,color:""};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      return {
        ...state,
        text: action.payload.text,
        display: action.payload.display,
        color : action.payload.color,
      };
    },
    clearMessage: () => {
      return {
        text: "",
        display: false,
      };
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, clearMessage } = actions;
export default reducer;
