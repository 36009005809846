import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import React from "react";
import { useSelector } from "react-redux";

export default function TextFieldEval(props) {
  const { evaluation: evaluation } = useSelector((state) => state);
  return (
    <>
    <h5>{props.block.consigne}</h5>
    <p>{props.block.question}</p>
    <TextField
      key={props.block.id}
      id={props.block.id}
      name={props.block.id}
      label={props.block.label}
      type={props.block.type}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{props.block.suffix}</InputAdornment>
        ),
      }}
      variant={props.block.variant}
      InputLabelProps={{ shrink: true }}
      onChange={props.handleChange}
      value={
        props.block.name_test
          ? evaluation.selectedEvaluation[props.block.name_test][props.block.id]
          : evaluation.selectedEvaluation[props.block.id]
      }
    />
    </>
  );
}
