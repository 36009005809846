import { Button, Grid, Box } from "@mui/material";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getempruntfiltered, setEmprunt } from "../redux/slices/emprunt";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { red, green } from "@mui/material/colors";
import moment from "moment";



export default function UserFormRetour() {
  const dispatch = useDispatch();

  const { emprunt: empruntObject } = useSelector((state) => state);
  const [empruntfiltered, setEmpruntFiletered] = useState({
    list: [],
    is_searched: false,
  });

  const [emprunteur, setEmprunteur] = useState({
    nom_emprunteur: "",
    prenom_emprunteur: "",
    societe_emprunteur: "",
    telephone_emprunteur: "",
  });
  let history = useHistory();

  const handleChange = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    setEmprunteur((emprunteur) => ({ ...emprunteur, [id]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(getempruntfiltered(emprunteur))
      .unwrap()
      .then((response) => {
        setEmpruntFiletered({
          ...empruntfiltered,
          list: response.data,
          is_searched: true,
        });
      });
  };

  const onReturnEmprunt = (e,id) => {
    e.preventDefault();
  

    history.push(`/retour-materiel/${id}`);
  };

  const onRecommencer = (e) => {
    e.preventDefault();
    setEmpruntFiletered({
      ...empruntfiltered,
      list: [],
      is_searched: false,
    });
  };


  const columns = [
    {
      field: "materiel",
      headerName: "Modèle",
      valueGetter: (params) => {
        return params.value.modele;
      },
      flex: 1,
    },
  
    {
      field: "date_start_emprunt",
      headerName: "Date de l'emprunt",
      renderCell: (params) => (
        <div>{moment(params.value).format("DD/MM/YYYY HH:mm")}</div>
      ),
      flex: 1,
    },
  
    {
      field: "date_end_emprunt",
      headerName: "Date rendu",
      renderCell: (params) => (
        <div>
          {params.value === "" || params.value === null
            ? "Pas encore rendu"
            : moment(params.value).format("DD/MM/YYYY HH:mm")}
        </div>
      ),
      flex: 1,
    },
  
    {
      field: "is_en_pret",
      headerName: "Rendu ?",
      renderCell: (params) => (
        <div>
          {params.value ? (
            <>
              {" "}
              <CloseIcon sx={{ color: red[500] }}></CloseIcon>
              <Button
                type="button"
                onClick={(e) => onReturnEmprunt(e,params.row.id)}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Rendre
              </Button>
            </>
          ) : (
            <DoneIcon sx={{ color: green[500] }}></DoneIcon>
          )}
        </div>
      ),
      flex: 1,
    },
  ];

  return (
    <div>
      <h5 align="center">Retour Matériel</h5>

      {empruntfiltered.is_searched === false ? (
        <>
          <Container component="main" maxWidth="xs">
            <Grid
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <hr />
              <h5 align="center">
                Veuillez renseigner un des champs pour retrouver vos emprunts
              </h5>

              <Grid item>
                <TextField
                  id="nom_emprunteur"
                  name="nom_emprunteur"
                  label="Nom"
                  value={emprunteur.nom_emprunteur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="prenom_emprunteur"
                  name="prenom_emprunteur"
                  label="Prénom"
                  value={emprunteur.prenom_emprunteur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="societe_emprunteur"
                  name="societe_emprunteur"
                  label="Société/Service"
                  value={emprunteur.societe_emprunteur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="telephone_emprunteur"
                  name="telephone_emprunteur"
                  label="Téléphone"
                  value={emprunteur.telephone_emprunteur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  onClick={(e) => onSubmit(e)}
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Valider
                </Button>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <></>
      )}

      <Box pt={2}></Box>
      {empruntfiltered.list.length !== 0 && empruntfiltered.is_searched ? (
        <>
          <div style={{ height: 350, width: "100%" }}>
            <DataGrid
              disableColumnFilter
              autoHeight={true}
              columns={columns}
              rows={empruntfiltered.list}
            />
          </div>
        </>
      ) : (
        <>
          {empruntfiltered.list.length === 0 && empruntfiltered.is_searched ? (
            <>
              <h1 align="center">Aucun emprunt n'a été trouvé</h1>
              <Button
                type="button"
                onClick={(e) => onRecommencer(e)}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Recommencer une recherche
              </Button>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}
