import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useSelector } from "react-redux";

export default function SelectCustom(props) {
  const { emprunt: emprunt } = useSelector((state) => state);

  return (

    <FormControl style={{ minWidth: 200, width: "100%" }}>
      <Autocomplete

        id={props.block.id}
        name={props.block.id}
        autoHighlight

        onChange={props.handleChange}
        value={props.state? props.state[props.block.id] : emprunt.selectedEmprunt[props.block.id]}

        onInputChange={props.handleChange}
        inputValue={props.state? props.state[props.block.id] : emprunt.selectedEmprunt[props.block.id]}
        
        sx={{ width: 250 }}
        renderInput={(params) => <TextField {...params}  label={props.block.label} />}
        options={props.block.choices}
      >
      
      </Autocomplete>
    </FormControl>
  );
}
