import { Grid, Button, Box } from "@mui/material";
import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  addemprunt,
  editemprunt,
  getallmateriel,
  getemprunt,
} from "../redux/slices/emprunt";
import Components from "../components/components.js";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";

import FormControl from "@mui/material/FormControl";
const schema_emprunteur = {
  body: [
    {
      component: "textfield",
      id: "nom_emprunteur",
      type: "text",
      label: "Nom",
      variant: "outlined",
    },
    {
      component: "textfield",
      id: "prenom_emprunteur",
      type: "text",
      label: "Prénom",
      variant: "outlined",
    },
    {
      component: "textfield",
      id: "societe_emprunteur",
      type: "text",
      label: "Société/Service",
      variant: "outlined",
    },
    {
      component: "textfield",
      id: "telephone_emprunteur",
      type: "text",
      label: "Téléphone",
      variant: "outlined",
    },
  ],
};

const schema_emprunt = {
  body: [
    {
      component: "textfield",
      id: "date_start_emprunt",
      type: "datetime-local",
      label: "Date d'emprunt",
      variant: "outlined",
    },
  ],
};

export default function AddEmprunt() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isAddMode = !id;

  const { emprunt: empruntObject } = useSelector((state) => state);
  const [materielValue, setMaterialValue] = useState("");
  const [emprunt, setEmprunt] = useState({
    // nom_emprunteur: "",
    // prenom_emprunteur: "",
    // societe_emprunteur: "",
    // telephone_emprunteur: "",
    date_start_emprunt: new Date().toISOString().slice(0, 16),
    // date_end_emprunt: "",
    // observations: "",
    situation: "En cours",
    materiel: "",
  });
  let history = useHistory();

  const handleChange = (e) => {
    let id = e.target.id;
    let value = e.target.value;

    setEmprunt((emprunt) => ({ ...emprunt, [id]: value }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setEmprunt({
      ...emprunt,
      date_start_emprunt:
        emprunt.date_start_emprunt === ""
          ? null
          : moment(emprunt.date_start_emprunt).format("YYYY-MM-DD HH:mm"),
    });

    if (isAddMode) {
      // case ajout emprunt
      dispatch(addemprunt(emprunt))
        .unwrap()
        .then(() => {
          history.push("/home/");
        })
        .catch(() => {});
    } else {
      // case edit emprunt
      dispatch(editemprunt({ id, emprunt }))
        .unwrap()
        .then(() => {
          history.push("/home/");
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      // get emprunt and set fields value
      dispatch(getemprunt(id))
        .unwrap()
        .then((output) => {
          setEmprunt(output.data);
        });
    }
  }, []);
  return (
    <div>
      <h1 align="center">FORMULAIRE EMPRUNT</h1>
      <Container component="main">
        <Grid
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <h5 align="center">Informations emprunteurs</h5>
          <Box pt={2}></Box>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            {schema_emprunteur.body.map(function (block, i) {
              return (
                <Grid item key={i}>
                  {Components(block, handleChange, emprunt)}
                </Grid>
              );
            })}
          </Grid>
          <hr />
          <h5 align="center">Informations de l'emprunt</h5>

          {schema_emprunt.body.map(function (block, i) {
            return (
              <Grid item key={i}>
                {Components(block, handleChange, emprunt)}
              </Grid>
            );
          })}
          <Grid item>
            <FormControl style={{ minWidth: 450, width: "100%" }}>
              <Autocomplete
                id="materiel"
                name="materiel"
                disablePortal
                // autoHighlight
                onChange={(event, newValue) => {
                  setMaterialValue(newValue.modele);
                  setEmprunt({ ...emprunt, materiel: newValue.id });
                }}
                value={materielValue}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField {...params} label={"Matériel"} />
                )}
                options={empruntObject.listmateriel}
              ></Autocomplete>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              style={{ width: 500 }}
              id="observations_start_emprunt"
              name="observations_start_emprunt"
              label="Observation"
              multiline
              minRows={6}
              value={emprunt.observations_start_emprunt}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <Button
              type="button"
              onClick={(e) => onSubmit(e)}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Valider
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
