import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";

import { useSelector } from "react-redux";

export default function SelectButtonCustom(props) {
  const { evaluation: evaluation } = useSelector((state) => state);

  return (
    <div>
        {props.block.commentaire ? <p  style={{ fontStyle: 'italic' }} >{props.block.commentaire}</p> : <></>}
        <Box pt={1}></Box>
      <FormControl component="fieldset">
        <FormLabel component="legend"><h5 style={{ color: 'blue' }} >{props.block.label}</h5></FormLabel>
        <RadioGroup
          row
          aria-label={props.block.id}
          name="row-radio-buttons-group"
          value={ props.block.name_test ? 
            evaluation.selectedEvaluation[props.block.name_test][props.block.id] 
            : evaluation.selectedEvaluation[props.block.id] 
          }
          onClick={(e) => {
            props.handleChange(e, {
              name: props.block.id,
              value: e.target.value,
            });
          }}
        >
          {Object.keys(props.block.choices).map((key, i) => {
            return (
              <FormControlLabel
                value={props.block.choices[key]}
                control={<Radio />}
                label={key}
              />
            );
          })}
        </RadioGroup>
        
      
      </FormControl>
      <hr></hr>
    </div>
  );
}
