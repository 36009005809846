import TextField from "@mui/material/TextField";
import React from "react";

export default function TextFieldCustom(props) {
  return (
    <TextField
      key={props.block.id}
      id={props.block.id}
      disabled = {props.block.disabled}

      name={props.block.id}
      label={props.block.label}
      type={props.block.type}
      variant={props.block.variant}
      InputLabelProps={{ shrink: true }}
      onChange={props.handleChange}
      value={props.state[props.block.id]}
      
    />
  );
}
