import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";


import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Route, Switch, useLocation
} from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import Home from "./pages/Home";
import { logout, verifytoken } from "./redux/slices/auth";
import { clearMessage } from "./redux/slices/message";

import HeaderBar from "./components/Headerbar";
import AddEmprunt from "./pages/AddEmprunt";
import { getallmateriel } from "./redux/slices/emprunt";
import UserFormRetour from "./pages/UserFormRetour";
import ReturnEmprunt from "./pages/ReturnEmprunt";
import RechercheEmprunt from "./pages/RechercheEmprunt";


function App() {
  const dispatch = useDispatch();

  const { user: currentUser } = useSelector((state) => state.auth);
  const { message: message } = useSelector((state) => state);
  const closeSnackbar = () => {
    dispatch(clearMessage());
  };

  useEffect(() => {
    dispatch(getallmateriel())

    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      dispatch(
        verifytoken({
          token: user.auth_token,
        })
      );
    } catch (error) {
      localStorage.removeItem("user");
      dispatch(logout());
    }
  }, []);
  const location = useLocation();
  return (
    <div>
      {message && (
        <div className="form-group">
          <Snackbar
            open={message.display}
            autoHideDuration={6000}
            onClose={closeSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <MuiAlert onClose={closeSnackbar} severity={message.color}>
              {message.text}
            </MuiAlert>
          </Snackbar>
        </div>
      )}

      {currentUser ? (
        <>
        <HeaderBar></HeaderBar>
        <Box pt={8}></Box>

          <Switch>
            <Route path={["/home"]} component={Home} />
            <Route path={["/emprunt"]} component={AddEmprunt} />
            <Route path="/emprunt-filter" component={UserFormRetour}></Route>
            <Route path={["/retour-materiel/:id"]} component={ReturnEmprunt} />
            <Route path={["/recherche-emprunt"]} component={RechercheEmprunt} />

            
            {/* {PatientRoute}
            {EvaluationRoute} */}
          </Switch>
        </>
      ) : (
        <>
        <Switch>
          <Login></Login></Switch>
        </>
      )}
    </div>
  );
}

export default App;
